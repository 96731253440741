<template>
  <v-container>
    <h3 class="mb-2">Pacientes com Agendamento</h3>

    <CrudTable
      tabelaKey="id"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="prontuarios"
      :pagination="paginacao"
      :loading="carregando"
      :keyPaginacao="keyPaginacao"
      :itemsPerPage="itensPorPagina"
      @actualPage="page"
      @clickRow="detalharPaciente($event)"
    >
      <template v-slot:top>
        <v-toolbar flat :class="$vuetify.breakpoint.xs ? 'scroll' : ''">
          <v-spacer></v-spacer>

          <ButtonMain
            @click.native="abrirFiltro()"
            text="filtrar"
            :primary="true"
          ></ButtonMain>

          <ButtonMain
            v-if="filtroAplicado"
            text="Limpar filtros"
            class="ml-2 warning"
            :primary="true"
            @click.native="
              limparFiltro()
              filtroAplicado = false
            "
          ></ButtonMain>

          <DialogConfirmation
            title="Filtros"
            :scrollable="true"
            :width="720"
            :show="dialogFiltroAberto"
            :key="keyDialogFiltro"
            :tag="'add'"
            @close="dialogFiltroAberto = false"
            @confirm="
              aplicarFiltro()
              dialogFiltroAberto = false
            "
          >
            <template v-slot:body>
              <v-row class="mt-3">
                <InputField
                  label="Nome do Paciente"
                  :cols="12"
                  :valueField="filtro.nome"
                  @update:value="filtro.nome = $event"
                />

                <InputMaskField
                  label="CPF"
                  maskFormat="###.###.###-##"
                  :valueField="filtro.cpf"
                  :cols="12"
                  @update:value="filtro.cpf = $event"
                />

                <!-- <DateField
                  cols="6"
                  label="Data de Criação"
                  :dateFormatted="filtro.data"
                  :date="filtro.data"
                  :valueField="filtro.data"
                  :readonly="true"
                  @dateSelected="filtro.data = $event"
                /> -->
              </v-row>
            </template>
          </DialogConfirmation>
        </v-toolbar>
      </template>

      <template v-slot:[`item.cpf`]="{ item }">
        <span>{{ item.cpf | VMask('###.###.###-##') }}</span>
      </template>
    </CrudTable>
  </v-container>
</template>

<script>
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
import CrudTable from '@/components/molecules/tables/CrudTable'
import ButtonIcon from '@/components/atoms/buttons/ButtonIcon.vue'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import agendaService from '@/services/AgendaService'
import StatusChip from '@/components/atoms/StatusChip.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import InputMaskField from '@/components/atoms/fields/InputMaskField.vue'
import InputField from '@/components/atoms/fields/InputField.vue'
import DateField from '@/components/atoms/fields/DateField.vue'
import SelectField from '@/components/atoms/fields/SelectField.vue'
import TooltipMain from '@/components/atoms/TooltipMain.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import globalMethods from '@/mixins/globalMethods.vue'

export default {
  components: {
    CrudTable,
    ButtonMain,
    ButtonIcon,
    StatusChip,
    DialogConfirmation,
    InputField,
    SelectField,
    InputMaskField,
    TooltipMain,
    FormField,
    TitleH2,
    DateField
  },

  mixins: [globalMethods],

  data() {
    return {
      carregando: false,
      filtroAplicado: false,
      dialogFiltroAberto: false,
      keyPaginacao: 0,
      keyDialogFiltro: 0,
      itensPorPagina: 15,

      dadosColaborador: null,

      filtro: {
        nome: '',
        cpf: ''
      },
      paginacao: {
        page: 0,
        size: this.itensPorPagina,
        total: 0
      },
      validadores: {
        obrigatorio: 'Campo obrigatório',
        invalido: 'Campo inválido'
      },

      prontuarios: [],

      headers: [
        {
          text: 'Nome do Paciente',
          value: 'nome',
          width: 350,
          sortable: false
        },
        { text: 'CPF', value: 'cpf', align: 'center', sortable: false }
      ]
    }
  },

  created() {
    this.getDadosColaborador()
  },

  methods: {
    getDadosColaborador() {
      this.getColaboradorLogado()
        .then(({ data }) => {
          this.dadosColaborador = data

          const paginacao = {
            page: this.paginacao.page,
            size: this.itensPorPagina
          }
          this.filtroPacientes(paginacao)
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    filtroPacientes(paginacao) {
      this.carregando = true
      const filtro = {
        ...this.filtro,
        ...paginacao,
        medico: this.dadosColaborador?.id || null,
        cpf: this.filtro?.cpf.replace(/[^0-9.]/, '') || ''
      }

      agendaService
        .pacientes(filtro)
        .then(({ data }) => {
          this.carregando = false
          this.prontuarios = data.content
          this.paginacao.total = data.totalPages
        })
        .catch((err) => {
          this.carregando = false
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    abrirFiltro() {
      this.resetItensFiltro()
      this.dialogFiltroAberto = true
    },

    resetItensFiltro() {
      Object.keys(this.filtro).forEach((key) => (this.filtro[key] = ''))
    },

    aplicarFiltro() {
      ++this.keyPaginacao
      this.filtroAplicado = true
      this.carregando = true
      this.paginacao.page = 1
      const paginacao = { page: 0, size: this.itensPorPagina }
      this.filtroPacientes(paginacao)
    },

    limparFiltro() {
      ++this.keyPaginacao
      this.carregando = true
      this.resetItensFiltro()
      this.paginacao.page = 1
      const paginacao = { page: 0, size: this.itensPorPagina }
      this.filtroPacientes(paginacao)
      this.carregando = false
    },

    detalharPaciente(paciente) {
      if (!paciente) {
        this.$root.vtoast.show({
          status: 'error',
          text: 'Paciente não encontrado',
          title: 'Erro!'
        })
        return
      }

      const param = { id: paciente.id, dependente: paciente.dependente }
      this.$router.push({
        name: 'DetalhesProntuarioMedico',
        params: { slug: btoa(JSON.stringify(param)) }
      })
    },

    page(page) {
      this.carregando = true
      this.paginacao.page = page - 1
      const paginacao = { page: this.paginacao.page, size: this.itensPorPagina }
      this.filtroPacientes(paginacao)
      this.carregando = false
    }
  }
}
</script>

<style scoped>
.btn-buscar {
  margin: auto 0;
  height: 3.3rem !important;
  width: 10rem !important;
}
</style>